import React from "react";
import Layout from "components/layout";
import Leadership from "components/Leadership";
import SEO from "components/seo";

const Index = () => {
  return (
    <Layout>
      <SEO
        title="The Tomedes Leadership Team"
        description="Meet the dedicated leaders driving Tomedes' vision forward. Discover their stories, values, and commitment to excellence in every endeavor."
        slug="/about/leadership"
      />
      <Leadership />
      {/* <iframe
        width="100%"
        height="700"
        src="https://machine-translation-next-git-feat-embed-translation-bytequest.vercel.app/embeded/translation"
        title="MT video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
    </Layout>
  );
};

export default Index;
